import "styles/pages/page-thank-you.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import { Link } from "gatsby";

const ThankYouPage = () => {
  return (
    <Layout headerColor="#FFCE2E">
      <Seo title="Wiadomość została wysłana" />
      <SubpageHeader
        image={require("assets/images/thank-you-bg-main.jpg").default}
      />
      <section className="page-thank-you">
        <div className="container">
          <div className="page-thank-you__content">
            <h2 className="page-thank-you__title">
              Zapisałeś się do newslettera!
            </h2>
            <div className="page-thank-you__text">
              <strong>
                {" "}
                Dziękujemy za zapisanie się do naszego newslettera.
              </strong>{" "}
              <br />
              <br />
              Od tej pory będziesz otrzymywać najnowsze informacje o <br />{" "}
              wydarzeniach związanych z Tajlandią!
            </div>
            <Link to="/" className="button">
              Strona główna
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ThankYouPage;
